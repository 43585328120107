import { useState } from "react";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { FaCheck } from "react-icons/fa";

import Translated from "../../feature/Translated";
import Spinner from "../Spinner";
import { useExportInvoice } from "../../../api-new";
import { useTranslation } from "../TranslationProvider";

/**
 * Button that requests all given invoices to be exported to Netvisor.
 * @param invoiceIdsArr Array of the invoices to be exported.
 * @returns {JSX.Element}
 * @constructor
 */
const InvoiceExportToNetvisorButton = ({invoiceIdsArr, callback}) => {
  const { translate } = useTranslation();
  const [exportResult, setExportResult] = useState(null);
  const exportInvoiceMutation = useExportInvoice();

  const exportInvoices = () => {
    exportInvoiceMutation.mutate(
      { invoiceIds: invoiceIdsArr },
      {
        onSuccess: (data) => {
          setExportResult(true);
          callback && callback();
        },
        onError: (error) => {
          setExportResult(error.message)
        }
      }
    );
  };

  const renderExportResult = () => {
    if (exportResult === true) {
      return (
        <div>
          <FaCheck />
        </div>
      );
    } else {
      return (
        <div className="blue-inner-box tight">
          {exportResult}
        </div>
      );
    }
  }

  if (exportInvoiceMutation.isPending) {
    return <Spinner />;
  } else if (exportInvoiceMutation.isError) {
    return <Alert>{translate(exportInvoiceMutation.error.message)}</Alert>;
  } else if (exportResult !== null) {
    return renderExportResult();
  } else {
    const invoiceAmountLabel = ' (' + invoiceIdsArr.length + ')';
    return (
      <Button className="top-margin" onClick={exportInvoices}>
        <span><Translated translationKey="export_invoice"/>{invoiceAmountLabel}</span>
      </Button>
    );

  }
};

export default InvoiceExportToNetvisorButton;
