import { useEffect, useRef } from "react";
import Alert from "react-bootstrap/Alert";

import Spinner from "../Spinner";
import InvoiceListItem from "./InvoiceListItem";
import Translated from "../Translated";
import { useInvoices } from "../../../api-new";
import InvoiceExportToNetvisorButton from "./InvoiceExportToNetvisorButton";

const ALLOWED_FILTERS = ["startDate", "endDate", "stateOpen", "schoolId"];

const InvoiceList = ({ filters, immediate }) => {
  const invoicesQuery = useInvoices(filters);
  const prevFiltersRef = useRef(filters);

  useEffect(() => {
    if (immediate) {
      invoicesQuery.refetch();
    }
  }, []);

  useEffect(() => {
    if (haveFiltersChanged(prevFiltersRef.current, filters)) {
      invoicesQuery.refetch();
      prevFiltersRef.current = filters;
    }
  }, [filters]);

  const haveFiltersChanged = (prevFilters, newFilters) => {
    const a = prevFilters ? prevFilters : {};
    const b = newFilters ? newFilters : {};
    return ALLOWED_FILTERS.reduce((hasChanges, filterName) => {
      return hasChanges || a[filterName] !== b[filterName];
    }, false);
  };

  if (invoicesQuery.isFetching) {
    return <Spinner />;
  }

  if (invoicesQuery.isError) {
    return (
      <Alert>
        <Translated translationKey={invoicesQuery.error.message} />
      </Alert>
    );
  }

  if(!invoicesQuery.data) {
    return;
  }

  const items = invoicesQuery.data.map((invoice) => {
    return (
      <InvoiceListItem
        invoiceData={invoice}
        key={invoice.id}
        refetchInvoice={invoicesQuery.refetch}
      />
    );
  });

  let unexportedInvoiceIds = invoicesQuery.data
    .filter(invoice => !invoice.netvisorId) // Does not contain Netvisor id
    .map(invoice => invoice.id); // Get the id

  // Temporarily limit invoice export amount per one call, before batch exporting is implemented.
  // Keep batch size small to avoid timeouts.
  // Note that the customer adding to Netvisor might take more time than anticipated, when customer is missing from Netvisor.
  unexportedInvoiceIds = unexportedInvoiceIds.slice(0, 20);

  return (
    <div>
      {unexportedInvoiceIds && unexportedInvoiceIds.length > 0 && <InvoiceExportToNetvisorButton invoiceIdsArr={unexportedInvoiceIds} callback={() =>invoicesQuery.refetch()}/>}
      {items}
    </div>
  );
};


export default InvoiceList;
